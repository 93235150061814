import { toast } from "react-toastify";
import { deleteRequest, get, post, put } from "./apiClients";
import { store } from "../store";
import {
  setAllContentDetails,
  setContentById,
  setIsContentByIdLoading,
  setIsContentTableLoading,
  setIsEditContentLoading,
  setSelectedAllTagsByAssestId,
  setIsAllTagsByAssestIdLoading,
  setIsUpdateTagsByAssestIdLoading,
  refreshedAssetByAssetId,
  setTotalContentCount,
  setContentDeleteLoading,
  contentByIdInitialValue,
  tagsByAssestIdInitValue,
  autoRefreshContent,
  updateUploadedContent,
  setContentExportCSV,
  setIsContentExportCSVLoading,
  setFileUploadProgress,
  setURLUploadProgress,
  setDownloadLogError,
  setDownloadLimit,
  setIsFetchDownloadLimit,
  setIsSaveDownloadLimit,
  setIsRunVisionLoading,
  setIsProcessStopLoading,
  updatePageOnContentDelete,
} from "../store/contentManagement/contentSlice";
import { IContent } from "../store/contentManagement/content.interface";
import { PageLimit, PublishedOption, PATHS, FileName, onDownloadPdf } from "../shared";
import { fetchAllUsers } from "./userManagement";
import { NavigateFunction } from "react-router-dom";

export const uploadAsset = async (
  formData: FormData,
  userId: number,
  content_type: string,
  navigate: NavigateFunction,
  runVision: boolean
) => {
  try {
    const { data } = await post(`/core/asset/assetUpload`, formData, {
      params: {
        userId,
        content_type,
        ["vision-processing"]: runVision,
      },
      onUploadProgress: function (progressEvent) {
        if (progressEvent?.loaded && progressEvent?.total) {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          store.dispatch(setFileUploadProgress(percentCompleted));
        }
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    store.dispatch(updateUploadedContent(data[0]));
    store.dispatch(setContentById(contentByIdInitialValue));
    store.dispatch(autoRefreshContent({ assetId: data[0]?.asset_id }));
    store.dispatch(setSelectedAllTagsByAssestId(tagsByAssestIdInitValue));

    navigate(`${PATHS.viewEditContentManagement}/${data[0]?.asset_id}`, {
      state: { isEditMode: true, isNewAssetUpload: true },
    });
    toast.success("Asset uploaded successfully");
    return data;
  } catch (error: any) {
    console.log("UploadMainRfp api Error", error?.error);
    toast.error(error?.error ?? "Failed to upload asset");
    return error;
  }
};

export const autoRefreshedAssetById = async (assetId: number): Promise<IContent> => {
  try {
    const data = await get(`core/asset/assetInfoForUser/${assetId}`);
    store.dispatch(refreshedAssetByAssetId({ data, id: assetId }));

    return data;
  } catch (err: any) {
    console.log("get AssetBy Id api error", err);
    store.dispatch(autoRefreshContent({ assetId: -1 }));
    return err;
  }
};

export const getAllContent = async (searchText = "", page = 1, isPublished = "") => {
  try {
    store.dispatch(setIsContentTableLoading(true));
    const isPublishedFilter = isPublished ? isPublished : PublishedOption[0].value;
    const { content, totalElements } = await get(
      `/core/asset/searchByUserAndTitleOrSummary?titleOrSummary=${searchText}&page=${
        page - 1
      }&limit=${PageLimit.ContentManagement}&isPublished=${isPublishedFilter}`
    );
    const contentRes = content ?? [];
    const totalElementsRes = totalElements ?? 0;

    store.dispatch(setAllContentDetails(contentRes));
    store.dispatch(setTotalContentCount(totalElementsRes));
    return contentRes;
  } catch (error) {
    console.log(error);
    store.dispatch(setAllContentDetails([]));
    store.dispatch(setTotalContentCount(0));
    return error;
  } finally {
    store.dispatch(setIsContentTableLoading(false));
  }
};

const handleGetTitle = (res: IContent) => {
  let title = res.title;
  if (!title) {
    if (res.asset_url) {
      title = res.asset_url;
    } else {
      title = res.original_file_name;
    }
  }

  return title;
};

export const getAssetById = async (
  assetId: number,
  setContent: (v: IContent) => void,
  navigate: NavigateFunction
) => {
  try {
    store.dispatch(setIsContentByIdLoading(true));
    const res = await get(`/core/asset/assetInfoForUser/${assetId}`);
    fetchAllUsers("");
    store.dispatch(setContentById(res));
    setContent({ ...res, title: handleGetTitle(res) });
    return res;
  } catch (error: any) {
    if (error && error?.status === 404) {
      navigate(-1);
    }
    return error;
  } finally {
    store.dispatch(setIsContentByIdLoading(false));
  }
};

export const editAssetById = async (
  assetId: number,
  title: string,
  summary: string,
  assetType: string,
  userId: number,
  contributors: { id: number }[],
  summaryStatus: string | null,
  taxonomyNodeIds: number[]
) => {
  try {
    store.dispatch(setIsEditContentLoading(true));
    await put(`/core/asset/editAsset`, {
      asset_id: assetId,
      title,
      summary,
      assetType,
      user: { id: userId },
      contributors: contributors,
      summaryStatus,
      taxonomyNodeIds,
    });

    // getAllTagsByAssestId(assetId);
    toast.success("Successfully saved asset details");
  } catch (error: any) {
    console.log(error);
    toast.error(error?.error ?? "Failed to save asset details");
  } finally {
    store.dispatch(setIsEditContentLoading(false));
  }
};

export const deleteAssetById = async (
  assetId: number,
  searchText: string,
  currentPage: number,
  publishedFilter: string,
  totalContentsCount: number,
  isCallGetAllContent: boolean
) => {
  store.dispatch(setContentDeleteLoading(true));

  try {
    const res = await deleteRequest(`/core/asset/deleteAssetInfo/${assetId}`);

    let newPage = currentPage;
    if (totalContentsCount % PageLimit.ContentManagement === 1) {
      newPage = newPage - 1;
    }

    toast.success("Asset deleted successfully");
    isCallGetAllContent && (await getAllContent(searchText, newPage, publishedFilter));

    if (totalContentsCount % PageLimit.ContentManagement === 1) {
      store.dispatch(updatePageOnContentDelete(newPage));
    }
    return res;
  } catch (error) {
    console.log(error);
    toast.error("Failed to delete Asset");
    return error;
  } finally {
    store.dispatch(setContentDeleteLoading(false));
  }
};

export const searchAsset = async (text: string) => {
  try {
    store.dispatch(setIsContentTableLoading(true));
    const res = await get(`/core/asset/searchByTitle?title=${text}`);
    store.dispatch(setAllContentDetails(res));
    return res;
  } catch (error) {
    console.log("search text error ", error);
    return error;
  } finally {
    store.dispatch(setIsContentTableLoading(false));
  }
};

export const enablePublish = async (id: number) => {
  try {
    const res = await put(`/core/asset/enable/${id}`);
    toast.success("Successfully published");
    return res;
  } catch (error) {
    console.log("enable publish error ", error);
    // return error;
  }
};

export const disablePublish = async (id: number) => {
  try {
    const res = await put(`/core/asset/disable/${id}`);
    toast.success("Successfully disabled");
    return res;
  } catch (error) {
    console.log("disable publish error ", error);
    // return error;
  }
};

export const getAllTagsByAssestId = async (assetId: number) => {
  try {
    store.dispatch(setIsAllTagsByAssestIdLoading(true));
    const res = await get(`/core/asset/linkedTags/${assetId}`);

    store.dispatch(setSelectedAllTagsByAssestId(res));
    return res;
  } catch (error) {
    console.log(error);
    return error;
  } finally {
    store.dispatch(setIsAllTagsByAssestIdLoading(false));
  }
};

export const saveTagsByAssestId = async (assetId: number, taxonomyNodeIds: number[]) => {
  try {
    store.dispatch(setIsUpdateTagsByAssestIdLoading(true));
    await post(`/core/asset/linkedTags/${assetId}`, {
      taxonomyNodeIds,
    });
    getAllTagsByAssestId(assetId);
    toast.success("Tags successfully saved.");
  } catch (error: any) {
    console.log(error);
    toast.error(error?.error ?? "Failed to save the Tags");
  } finally {
    store.dispatch(setIsUpdateTagsByAssestIdLoading(false));
  }
};

export const updateTagsByAssestId = async (assetId: number, taxonomyNodeIds: number[]) => {
  try {
    store.dispatch(setIsUpdateTagsByAssestIdLoading(true));
    await put(`/core/asset/linkedTags/${assetId}`, {
      taxonomyNodeIds,
    });
    getAllTagsByAssestId(assetId);
    toast.success("Tags successfully updated.");
  } catch (error: any) {
    console.log(error);
    toast.error(error?.error ?? "Failed to update the Tags");
  } finally {
    store.dispatch(setIsUpdateTagsByAssestIdLoading(false));
  }
};

export const uploadAssetURL = async (
  userId: number,
  uploadAssetsViaURL: string,
  navigate: NavigateFunction
) => {
  try {
    const { data } = await post(
      `/core/asset/assetUrlUpload?userId=${userId}&asset_url=${uploadAssetsViaURL}`,
      {},
      {
        onUploadProgress: function (progressEvent) {
          if (progressEvent?.loaded && progressEvent?.total) {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            store.dispatch(setURLUploadProgress(percentCompleted));
          }
        },
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    store.dispatch(setContentById(contentByIdInitialValue));
    store.dispatch(setSelectedAllTagsByAssestId(tagsByAssestIdInitValue));
    navigate(`${PATHS.viewEditContentManagement}/${data?.asset_id}`, {
      state: { isEditMode: true, isNewAssetUpload: true },
    });
    toast.success("Asset URL uploaded successfully");
    return data;
  } catch (error: any) {
    console.log("UploadMainRfp api Error", error?.error);
    toast.error(error?.error ?? "Failed to upload asset");
    return error;
  }
};

export const getContentExportCSV = async () => {
  store.dispatch(setIsContentExportCSVLoading(true));

  try {
    const res = await get(`/core/asset/csvFile`);
    store.dispatch(setContentExportCSV(res));
    onDownloadPdf(res, setIsContentExportCSVLoading, FileName.AssetSCV);
    toast.success("Asset successfully exported");
  } catch (error) {
    console.log(error);
    toast.error("Failed to export Asset. Please try again");
  } finally {
    store.dispatch(setIsContentExportCSVLoading(false));
  }
};

export const updateDownloadLogs = async (
  pre_signed_url: string,
  content: any,
  setIsPdfDownloading: (v: boolean) => void
) => {
  try {
    await post(`/core/file-download-logs/add-log`, {
      asset_id: content?.asset_id,
    });
    onDownloadPdf(pre_signed_url, setIsPdfDownloading, content?.original_file_name);
    store.dispatch(setDownloadLogError(null));
  } catch (error: any) {
    error?.errorCode === 400 ? store.dispatch(setDownloadLogError(error?.errorMessage)) : null;
    toast.error(error?.error ?? "Failed to download the file");
  }
};

export const getDownloadLimit = async (isLoading: boolean = true) => {
  store.dispatch(setIsFetchDownloadLimit(isLoading));
  try {
    const res = await get(`/core/file-download-logs/download-limit`);
    store.dispatch(setDownloadLimit(res));
  } catch (error) {
    store.dispatch(setDownloadLimit(0));
  } finally {
    store.dispatch(setIsFetchDownloadLimit(false));
  }
};

export const updateDownloadLimit = async (downloadLimit: number) => {
  try {
    store.dispatch(setIsSaveDownloadLimit(true));
    await put(`/core/file-download-logs/download-limit`, downloadLimit);
    store.dispatch(setDownloadLimit(downloadLimit));
    toast.success("Download limit successfully updated.");
  } catch (error: any) {
    toast.error(error?.error ?? "Failed to update the Download limit");
  } finally {
    store.dispatch(setIsSaveDownloadLimit(false));
  }
};

export const runVision = async (assetId: number) => {
  try {
    store.dispatch(setIsRunVisionLoading(true));

    const res = await post(`/core/asset/reprocess/${assetId}?vision-processing=true`);
    // toast.success("Vision successfully ran");
    return res;
  } catch (error: any) {
    toast.error(error?.error ?? "Failed to run vision");
  } finally {
    store.dispatch(setIsRunVisionLoading(false));
  }
};

export const getAssetDetailsByAssetId = async (assetId: number) => {
  try {
    const data = await get(`core/asset/assetInfoForUser/${assetId}`);
    return data;
  } catch (err: any) {
    console.log("get AssetBy Id api error", err);
    throw err;
  }
};

export const stopProcessing = async (assetId: number) => {
  try {
    store.dispatch(setIsProcessStopLoading(true));
    const res = await post(`/core/asset/stop-processing/${assetId}`);
    toast.success("Process stopping successfully started");
    return res;
  } catch (error: any) {
    toast.error(error?.error ?? "Failed to stop process");
  } finally {
    store.dispatch(setIsProcessStopLoading(false));
  }
};

export const generateSummary = async (assetId: number) => {
  try {
    const res = await post(`/core/asset/summarize-document/${assetId}`);
    toast.success("Summary generation successfully started");
    return res;
  } catch (error: any) {
    console.log("error  ===", error);
    toast.error(error?.errorMessage ?? "Failed to generate summary");
  }
};
